
.bg_cta{
    background-image: url("/assets/images/focus/banner1.jpg");
   background-repeat: no-repeat; 
   background-size: cover;
   background-position: center center;
   background-attachment: fixed;
  }
  .cta_heading{ color: white;}

  .why-choose-us-item .icon {
    color: #007bff;  /* Icon color */
    font-size: 40px;  /* Icon size */
    margin-right: 15px;
  }
  
  .why-choose-us-item h4 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .why-choose-us-item p {
    font-size: 14px;
    color: #555;
  }
  
  .thumbnail img {
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .why-choose-us-item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #e6e6e6;
}

/* New Banner CSS*/
.slider-wrapper {
    position: relative;
  }
  
  .slick-slide {
    position: relative;
  }
  
  .slide-image {
    position: relative;
    width: 100%;
    /*height: 00px;  You can adjust this height */
    background-size: cover;
    background-position: center;
  }
  
  .slide-content {
     
    bottom: 20px;
    left: 20px;
    color: #fff;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    text-align: center;
    padding-top: 300px;
  }
  
  .title1 {
    color: #fff;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    
    
  }
  
  .description {
    font-size: 18px;
  }
  
  .slick-prev, .slick-next {
    font-size: 30px;
    color: #fff;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  